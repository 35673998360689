import React, { Component } from 'react';
import Icon from '@mdi/react';
import { mdiAccount } from '@mdi/js'
import Dots from './Dots'

const SecsToHour = React.memo(({ secs }) => {
    var sec_num = parseInt(secs, 10); // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num % 3600) / 60);
    var seconds = Math.floor(sec_num % 60);

    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }
    return new Date(secs * 1000).toISOString().substr(11, 8);
})

class ListUsuarios extends Component {

    state = {
        elePos: 0
    }

    componentDidMount() {
        setInterval(() => {
            this.setState({ elePos: this.state.elePos + 1 })
            if (!document.querySelector('.list-users').children[this.state.elePos]) {
                this.setState({ elePos: 0 })
                this.sideScroll(0)
            } else {
                this.sideScroll(document.querySelector('.list-users').children[this.state.elePos].clientWidth)
            }
        }, 10000)

        Array.from(document.querySelector('.list-users').children).map((element, index) => {
            return element.style.left = `${element.clientWidth * index}px`
        })

        // window.addEventListener('keyup', (event) => {
        //     console.log(this.state)
        //     if (event.keyCode === 39) {
        //         this.setState({ elePos: this.state.elePos + 1 })

        //         if (!document.querySelector('.list-users').children[this.state.elePos]) {
        //             this.setState({ elePos: 0 })
        //             this.sideScroll(0)
        //         } else {
        //             this.sideScroll(document.querySelector('.list-users').children[this.state.elePos].clientWidth)
        //         }
        //     }
        // })
    }

    easeInOutQuad = (t) => {
        return t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t
    }

    animate(callbackObj, duration) {
        var requestAnimationFrame = window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.oRequestAnimationFrame || window.msRequestAnimationFrame;
        var startTime = 0, percentage = 0, animationTime = 0;

        duration = duration * 1000 || 1000;

        var animation = (timestamp) => {

            if (startTime === 0) {
                startTime = timestamp;
            } else {
                animationTime = timestamp - startTime;
            }

            if (typeof callbackObj.start === 'function' && startTime === timestamp) {
                callbackObj.start();

                requestAnimationFrame(animation);
            } else if (animationTime < duration) {
                if (typeof callbackObj.progress === 'function') {
                    percentage = animationTime / duration;
                    callbackObj.progress(percentage);
                }

                requestAnimationFrame(animation);
            } else if (typeof callbackObj.done === 'function') {
                callbackObj.done(1);
            }
        };

        return requestAnimationFrame(animation);
    }

    sideScroll(rangeInPixels) {
        var element = document.querySelector('.list-users')

        if (element) {
            var sequenceObj = {};
            var seconds = .6;
            var startingScrollPosition = element.scrollLeft;


            sequenceObj.progress = ((percentage) => {
                if (rangeInPixels === 0) {
                    element.scroll(startingScrollPosition - this.easeInOutQuad(percentage) * element.scrollWidth, 0)
                } else {
                    const scrollCalc = Math.round(startingScrollPosition + this.easeInOutQuad(percentage) * rangeInPixels)
                    element.scroll(scrollCalc, 0)
                    // console.log(scrollCalc + scrollGutter, scrollCalc, scrollGutter)
                }
            })

            sequenceObj.done = ((percentage) => {
                if (rangeInPixels === 0) {
                    element.scroll(startingScrollPosition - this.easeInOutQuad(percentage) * element.scrollWidth, 0)
                } else {
                    const scrollCalc = Math.round(startingScrollPosition + this.easeInOutQuad(percentage) * rangeInPixels)
                    element.scroll(scrollCalc, 0)
                    // console.log(scrollCalc + scrollGutter, scrollCalc, scrollGutter)
                }
            })

            this.animate(sequenceObj, seconds);
        }
    }

    render() {
        return (
            <div className="row no-gutters row-color">
                <div className="col-12">
                    <div className="row no-gutter box-usuario" style={{paddingRight: 15}}>

                        <div className="col-3 border-dashed" style={{ paddingLeft: 30 }}>
                            Nome
                        </div>

                        <div className="col-3 border-dashed">
                            Metas
                        </div>

                        <div className="col-3 border-dashed">
                            Atingido
                        </div>

                        <div className="col-3">
                            Tempo
                        </div>
                    </div>
                </div>
                <div className="list-users">
                {
                    this.props.list.map((chunk, cIndex) => {
                        return (<div className="row no-gutters add-magic" key={cIndex}>
                            {
                                chunk.map((user, index) => {
                                    return (
                                        <div className="col-12" key={index}>
                                            <div className="row box-usuario">
                                                <div className={`user-avatar empresa-${user.empresa_saas}`}>
                                                    {user.avatar ? <img src={`https://funil.pro/usuarios/avatar/${user.avatar}`} style={{borderRadius: '50%', width: 70}} /> : <div className="letter">{user.nome[0]}</div>}
                                                </div>

                                                <div className="col-3 no-gutter-custom border-dashed">
                                                    <div className="box-text">{user.nome}</div>
                                                </div>

                                                <div className="col-3 no-gutter-custom border-dashed">
                                                    <div className="box-text">{user.metas_atingir.join(' | ')}</div>
                                                </div>

                                                <div className="col-3 no-gutter-custom border-dashed">
                                                    <div className="box-text">{user.metas_atingidas}</div>
                                                </div>

                                                <div className="col-3 no-gutter-custom">
                                                    <div className="box-text last-block"><SecsToHour secs={user.tempo_em_chamada} /> </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>)
                    })
                }
                </div>
                
                <Dots dots={this.props.list.length} index={this.state.elePos} />
            </div>
        )
    }
}

export default ListUsuarios;