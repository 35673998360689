import React, { Component } from 'react';

class Dots extends Component {

    render() {

        if (this.props.dots > 1) {
            return (
                <div className="dots">
                    {Array.from(Array(this.props.dots)).map((item, index) => {
                        return (
                            <div key={index} className={`dot ${this.props.index === index ? 'marcado' : ''}`} />
                        )
                    })}
                </div>
            )
        } else {
            return <div></div>
        }
    }
}

export default Dots;