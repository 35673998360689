import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import axios from 'axios'
import ListEmpresas from './Components/ListEmpresas'
import ListUsuarios from './Components/ListUsuarios'

axios.defaults.baseURL = 'https://funil.pro'

class App extends Component {

    state = {
        listUsers: [],
        listEmpresas: [],
        loading: true
    }

    componentDidMount() {
        this.getInfoDash()
    }

    async getInfoDash() {
        try {
            const { data } = await axios.get('/metas/service.php?f=dashboardLigacoes')

            this.setState({ listUsers: data.usuarios, listEmpresas: data.empresas, loading: false })

            setInterval(() => this.updateDataUsers(), 60000)
            // this.updateDataUsers()
        } catch (e) {
            console.log(e)
        }
    }

    async updateDataUsers() {
        try {
            let { listEmpresas, listUsers } = this.state;
            let { data: updatedData } = await axios.post('/metas/service.php?f=dashboardLigacoesAtualizaUsers', this.state.listUsers)

            let test = []
            
            listEmpresas = listEmpresas.map((chunk) => {
                return chunk.map((empresa) => {
                    const total_ligacoes = updatedData.reduce((prev, curr) => curr.empresa_saas === empresa.saas_id ? prev += curr.atingido : prev, 0)
                    
                    return { ...empresa, total_ligacoes }
                })
            })
            
            listUsers = listUsers.map((chunk) => {
                return chunk.map((user) => {
                    const { atingido, tempo_ligacoes } = updatedData.find((update) => update.id === user.id)
                    
                    return { ...user, metas_atingidas: atingido, tempo_em_chamada: tempo_ligacoes }
                })
            })

            this.setState({ listUsers , listEmpresas })
        } catch (e) {
            console.log(e)
        }
    }

    render() {

        if (this.state.loading) return <h3>Carregando</h3>

        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-10">
                        <ListUsuarios list={this.state.listUsers} />
                    </div>

                    <div className="col-2">
                        <div className="row no-gutters row-color">
                            <div className="col-12">
                                <ListEmpresas list={this.state.listEmpresas} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default App;
