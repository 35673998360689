import React, { Component } from 'react';
import Icon from '@mdi/react';
import { mdiAccount } from '@mdi/js'
import Dots from './Dots'

class ListEmpresas extends Component {

    state = {
        elePos: 0
    }

    componentDidMount() {
        setInterval(() => {
            this.setState({ elePos: this.state.elePos + 1 })
            if (!document.querySelector('.list-empresas').children[this.state.elePos]) {
                this.setState({ elePos: 0 })
                this.sideScroll(0)
            } else {
                this.sideScroll(document.querySelector('.list-empresas').children[this.state.elePos].clientWidth)
            }
        }, 10000)

        // window.addEventListener('keyup', (event) => {
        //     console.log(this.state)
        //     if(event.keyCode === 39){
        //         this.setState({ elePos: this.state.elePos + 1 })

        //         if (!document.querySelector('.list-empresas').children[this.state.elePos]) {
        //             this.setState({ elePos: 0 })
        //             this.sideScroll(0)
        //         } else {
        //             this.sideScroll(document.querySelector('.list-empresas').children[this.state.elePos].clientWidth)
        //         }
        //     }
        // })
    }

    easeInOutQuad = (t) => {
        return t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t
    }

    animate(callbackObj, duration) {
        var requestAnimationFrame = window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.oRequestAnimationFrame || window.msRequestAnimationFrame;
        var startTime = 0, percentage = 0, animationTime = 0;

        duration = duration * 1000 || 1000;

        var animation = (timestamp) => {

            if (startTime === 0) {
                startTime = timestamp;
            } else {
                animationTime = timestamp - startTime;
            }

            if (typeof callbackObj.start === 'function' && startTime === timestamp) {
                callbackObj.start();

                requestAnimationFrame(animation);
            } else if (animationTime < duration) {
                if (typeof callbackObj.progress === 'function') {
                    percentage = animationTime / duration;
                    callbackObj.progress(percentage);
                }

                requestAnimationFrame(animation);
            } else if (typeof callbackObj.done === 'function') {
                callbackObj.done(1);
            }
        };

        return requestAnimationFrame(animation);
    }

    sideScroll(rangeInPixels) {
        var element = document.querySelector('.list-empresas')

        if (element) {
            var sequenceObj = {};
            var seconds = .6;
            var startingScrollPosition = element.scrollLeft;


            sequenceObj.progress = ((percentage) => {
                if (rangeInPixels === 0) {
                    element.scroll(startingScrollPosition - this.easeInOutQuad(percentage) * element.scrollWidth, 0)
                } else {
                    const scrollCalc = Math.round(startingScrollPosition + this.easeInOutQuad(percentage) * rangeInPixels)
                    element.scroll(scrollCalc, 0)
                }
            })

            sequenceObj.done = ((percentage) => {
                if (rangeInPixels === 0) {
                    element.scroll(startingScrollPosition - this.easeInOutQuad(percentage) * element.scrollWidth, 0)
                } else {
                    const scrollCalc = Math.round(startingScrollPosition + this.easeInOutQuad(percentage) * rangeInPixels)
                    element.scroll(scrollCalc, 0)
                }
            })

            this.animate(sequenceObj, seconds);
        }
    }

    render() {
        return (
            <div>
                <div className="list-empresas">
                    {
                        this.props.list.map((chunk, cIndex) => {
                            return (
                                <div key={cIndex} className="box-empresas">
                                    {chunk.map((empresa, index) => {
                                        return (
                                            <div className={`box-empresa empresa-${empresa.saas_id}`} key={index}>
                                                {/* {console.log(index % 4 === 0, index % 4, index)} */}
                                                <div className={`empresa-avatar empresa-${empresa.saas_id}`}>
                                                    <div style={{color: '#20c0df', margin: 6}}>GO</div>
                                                </div>
                                                <div className="empresa-nome">{empresa.saas_nome}</div>
                                                <div className="empresa-total-ligacoes">{empresa.total_ligacoes}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })
                    }
                </div>

                <Dots dots={this.props.list.length} index={this.state.elePos} />
            </div>
        )
    }
}

export default ListEmpresas;